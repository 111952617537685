import React from 'react';
import ImmutablePureComponent from 'react-immutable-pure-component';
import SearchContainer from 'brighteon/features/ui/components/search_container';
import SearchResultsContainer from 'brighteon/features/ui/components/search_results_container';
import horizontalLogo from 'brighteon/../images/brighteon-social/logo_horiz.svg';

export default class UnsignedNavigationBar extends ImmutablePureComponent {

  state = {
    searchResultShow: true,
  }

  handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setTimeout(() => this.setState({ searchResultShow: false }), 0); // iOS device a tag click does not trigger due to search results hidden early
    }
  }

  handleFocus = () => {
    this.setState({ searchResultShow: true });
  }

  render() {
    return (
      <div className='navigation-bar'>
        <div className='navigation-bar__inner'>
          <div className='d-flex'>
            <div className='head-logo'>
              <a href='/' >
                <img src={horizontalLogo} style={{ width: '100%' }} alt='horizontalLogo' />
              </a>
            </div>

            {
              <div className='desktop-container search-container flex-fill' onBlur={this.handleBlur}>
                <SearchContainer handleFocus={this.handleFocus} />

                <SearchResultsContainer focused={this.state.searchResultShow} />
              </div>
            }

            <div className='spacer' />

            <div className='d-flex'>
              <a href='https://support.brighteon.com/donate.html' className='decoration-none'>
                <button className='donate standard mr3'>
                  Donate
                </button>
              </a>

              <a rel='noopener noreferrer' href='/auth/sign_in' className='decoration-none'>
                <button className='standard mr3'>
                  Log In
                </button>
              </a>

              <a rel='noopener noreferrer' href='/auth/sign_up' className='decoration-none'>
                <button className='primary-button standard'>
                  Sign Up
                </button>
              </a>
            </div>
          </div>

          {
            <div className='mobile-container mobile-search-container' onBlur={this.handleBlur}>
              <SearchContainer handleFocus={this.handleFocus} />

              <SearchResultsContainer focused={this.state.searchResultShow} />
            </div>
          }
        </div>
      </div>
    );
  }

}
